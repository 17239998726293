import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Title = styled.span`
  display: block;
  font-size: 2rem;
  font-weight: 500;
  margin: 2rem 2rem 1rem;
`;

export const Url = styled.a`
  text-decoration: none;
  color: black;
`;

export const Tag1 = styled.p`
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0rem 2rem 1rem;
  padding: 0.5rem;
  border: 1px black;
  border-style: solid;
  border-radius: 10px;
`;

export const Tag2 = styled.p`
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0rem 0rem 1rem;
  padding: 0.5rem;
  border: 1px black;
  border-style: solid;
  border-radius: 10px;
`;

export const Container = styled.div`
  display: flex;
`;

export const Copy = styled.p`
  color: #757575;
  margin: 0 2rem 2rem;

  ${MEDIA.TABLET`
    margin-bottom: 4rem;
  `};
`;
