import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Title, Copy, Tag1, Tag2, Container, Url } from './item.css';

const Item = ({ title, copy, image, tag1, tag2, url }) => (
  <figure>
    <Img fluid={image ? image.childImageSharp.fluid : {}} alt={title} />
    <figcaption>
      <Url href={url} target="_blank" rel="noreferrer">
        <Title>{title}</Title>
      </Url>
      <Copy>{copy}</Copy>
      <Container>
        {tag1 && <Tag1>{tag1}</Tag1>}
        {tag2 && <Tag2>{tag2}</Tag2>}
      </Container>
    </figcaption>
  </figure>
);

Item.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  image: PropTypes.object.isRequired,
  tag1: PropTypes.string,
  tag2: PropTypes.string,
  url: PropTypes.string,
};

export default Item;
